import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'


import * as actions from '../store/results/_actions'
import * as quizActions from '../store/quiz/_actions'
import * as questActions from '../store/quest/_actions'

import Modal from '../components/Modal'



import './Results.scss'



const mapStateToProps = state => ({
  initialized: state.quiz.initialized,
  points: state.results.points,
  maxPoints: state.results.maxPoints,
  totalTime: state.results.totalTime,
  username: state.quiz.username,
  ranking: state.results.ranking,
  strings: state.quest.strings,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
  quizActions: bindActionCreators(quizActions, dispatch),
  questActions: bindActionCreators(questActions, dispatch),
})

class Results extends React.Component {

  exitTimeout = null
  redirectTimout = null

  constructor () {
    super(...arguments)

    this.state = {
      showModal: false,
      rankingToBeUpdated: false,
    }
  }

  handlePlayAgain () {
    const {
      actions: {
        resetPointsAndTime,
      },
      questActions: {
        resetQuests,
      },
    } = this.props

    this.setState({ showModal: true })

    this.redirectTimout = setTimeout(() => {
      resetQuests()
      resetPointsAndTime()
      this.props.history.push('/0') // @todo '/quest'
    }, 3500 )
  }

  handleRestart () {
    const {
      actions: {
        resetPointsAndTime,
      },
      quizActions: {
        resetQuiz,
      },
      questActions: {
        resetQuests,
      },
    } = this.props

    resetQuiz()
    resetQuests()
    resetPointsAndTime()

    this.props.history.push('/') // @todo '/quiz'
  }

  componentDidMount () {
    const {
      initialized,
      username,
      points,
      totalTime,
      actions: {
        resetPointsAndTime,
      },
      quizActions: {
        resetQuiz,
      },
      questActions: {
        resetQuests,
      },
    } = this.props

    // redirect if not initialized
    if (!initialized) {
      resetQuiz()
      resetQuests()

      this.props.history.push('/') // @todo change dynamic '/quiz'
    }

    // uninitialize so going back in history
    // will redirect to quiz landing view
    // resetQuizInit()

    this.setState({ rankingToBeUpdated: true })

    this.exitTimeout = setTimeout(() => {
      resetQuiz()
      resetQuests()
      resetPointsAndTime()
      this.props.history.push('/')
    }, 1000 * 60)
  }

  componentDidUpdate () {
    const {
      username,
      points,
      totalTime,
      ranking,
      actions: {
        addUserToRanking,
        resetPointsAndTime,
      },
    } = this.props

    if (this.state.rankingToBeUpdated) {
      let id = username + '_' + points + '_' + totalTime
      let exists = ranking.findIndex(r => r.id === id) !== -1

      if (!exists) {
        addUserToRanking({
          id,
          username,
          points,
          time: totalTime,
        })
      }

      this.setState({ rankingToBeUpdated: false })
    }
  }

  componentWillUnmount () {
    clearTimeout(this.exitTimeout)
    clearTimeout(this.redirectTimeout)
  }

  render () {
    const {
      points,
      maxPoints,
      totalTime,
      username,
      ranking,
      strings,
    } = this.props

    const {
      showModal
    } = this.state

    let minutes = ('0' + (Math.floor(totalTime / 60000) % 60)).slice(-2)
    let seconds = ('0' + (Math.floor(totalTime / 1000) % 60)).slice(-2)

    let resultTime = minutes + ':' + seconds

    return (
      <div className="view view--results">
        <p className="text--note">{ strings.your_results }</p>
        <p className="text--results">{ strings.result.replace('$1', points).replace('$2', maxPoints).replace('$3', resultTime) }</p>
        {/*{ points === 1 ? 'Point' : 'Points' }*/}

        {/*<div className="results">
          <ul>
            {ranking.map(r => {
              if (r.username === '')
                return ''

              let className = r.username === username
                ? 'result result--current'
                : 'result'

              return (
                <li className={ className } key={ r.username + '--' + r.points }>
                  <span className="result__name">Name: {r.username}</span>
                  <span className="result__points">Points: {r.points}</span>
                  <span className="result__time">Time: {r.time}</span>
                </li>
              )
            })}
          </ul>
        </div>*/}

        <div className="view__footer">
          <button className="btn btn-primary" onClick={ () => { this.handlePlayAgain() } }>{ strings.try_again }, { username }?</button>
          <button className="btn btn-primary" onClick={ () => { this.handleRestart() } }>{ strings.new_user }</button>
        </div>

        <Modal show={ showModal }>
          <p className="text--question">{ strings.get_ready }{ username.length > 0 ? ', ' + username : '' }!</p>
          <p className="text--note">{ strings.starting} <br/>{ strings.hint } <br/>{ strings.good_luck }</p>

          {/*<button  className="btn btn-primary" onClick={ this.handlePlayAgain() }>Play again, { username }</button>*/}
          {/*<button  className="btn btn-primary" onClick={ this.handlePlayAgain() }>Play again, { username }</button>*/}
        </Modal>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Results)