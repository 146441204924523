import initialState from './_state'
import * as constants from './_constants'


const dynamicSort = property => {
  return (a, b) => {
    return (a[property] < b[property])
      ? -1
      : (a[property] > b[property])
      ? 1
      : 0
  }
}

const results = ( state = initialState, action ) => {
  const {
    type,
  } = action

  let update = {...state}


  switch (type) {


    case constants.RESET_POINTS_AND_TIME :
      update.points = 0
      update.totalTime = 0
      break
    case constants.ADD_POINT :
      update.points = update.points + 1
      break


    case constants.UPDATE_RESULTS_TIME :
      update.totalTime += action.payload
      break


    case constants.ADD_USER_TO_RANKING :
      update.ranking.push(action.payload)
      update.ranking.sort(dynamicSort('points'))
      break

    default :
      break
  }

  return update
}

export default results