import React from 'react'
import { connect } from 'react-redux'



const mapStateToProps = state => ({
  points: state.results.points,
  maxPoints: state.results.maxPoints,
  totalTime: state.results.totalTime,
  username: state.quiz.username,
})



const Points = ({points, maxPoints, totalTime, username}) => (
  <div className="points">
    <span className="points__current">{ points }</span> / <span className="points__max">{ maxPoints }</span> <span className="username">{ username }</span>
  </div>
)



export default connect(mapStateToProps)(Points)