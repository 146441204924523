import initialState from './_state'
import * as constants from './_constants'


const results = ( state = initialState, action ) => {
  const {
    type,
  } = action

  let update = {...state}


  switch (type) {


    case constants.SET_USER_NAME :
      console.log(constants.SET_USER_NAME, action.payload)
      update.username = action.payload
      break


    case constants.INITIALIZE_QUIZ :
      update.initialized = true
      break

    case constants.RESET_QUIZ_INIT :
      update.initialized = false
      break

    case constants.RESET_QUIZ :
      update.username = ''
      update.rate = 0
      update.time = 0
      update.initialized = false

      console.log('setting initialized to false')
      break

    case constants.SAVE_USER_TO_RANKING :
      const {
        username,
        points,
        time,
      } = action.payload

      update.ranking.push(action.payload)

      update.ranking.sort(
        (a, b) => (a.points > b.points)
          ? 1
          : (a.points === b.points)
            ? (
              (a.time > b.time)
                ? 1
                : -1
              )
            : -1
      )

      console.log(username, points, time)
      break


    default :
      break
  }

  return update
}

export default results