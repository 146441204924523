import React from 'react'


import './Modal.scss'


class Modal extends React.Component {



  constructor () {
    super(...arguments)

    this.state = {
      active: false,
      background: false,
      content: false,
    }
  }


  componentDidUpdate () {
    const { show } = this.props
    const { active } = this.state

    if (show !== active)
      this.toggle()
  }

  toggle () {
    const { show } = this.props

    this.setState({ active: show })

    if (show)
      this.show()
    else
      this.hide()
  }

  show () {
    setTimeout(() => { this.setState({ showBackdrop: true }) }, 10)
    setTimeout(() => { this.setState({ showContent: true })}, 600)
  }

  hide () {
    this.setState({ showContent: false })

    setTimeout(() => { this.setState({ showBackdrop: false })}, 600)
  }

  render () {
    const {
      active,
      showBackdrop,
      showContent,
    } = this.state

    const { className } = this.props

    let classes = active
      ? 'modal modal--quest modal--active ' + className
      : 'modal modal--quest' + className

    return (
      <div className={ classes }>
        <div className={ showBackdrop ? 'modal__backdrop modal__backdrop--active' : 'modal__backdrop' } />

        <div className={ showContent ? 'modal__content modal__content--active' : 'modal__content' }>
          { this.props.children }
        </div>
      </div>
    )
  }
}



export default Modal