import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'



import * as actions from '../store/quest/_actions'
import * as timerActions from '../store/timer/_actions'
import * as resultsActions from '../store/results/_actions'
import * as quizActions from '../store/quiz/_actions'

import './Quest.scss'


import Navbar from '../components/Navbar--Quest'
import Modal from '../components/Modal'

import { ChoiceButton } from '../components/partials/buttons'
import { Link } from 'react-router-dom'



const mapStateToProps = state => ({
  initialized: state.quiz.initialized,
  quest: state.quest.current,
  choice: state.quest.current.choice,
  loaded: state.quest.loaded,
  nextLink: state.quest.nextLink,
  index: state.quest.index,
  ms: state.timer.ms,
  msLimit: state.config.questTimeLimit,
  strings: state.quest.strings,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
  quizActions: bindActionCreators(quizActions, dispatch),
  timerActions: bindActionCreators(timerActions, dispatch),
  resultsActions: bindActionCreators(resultsActions, dispatch),
})



class Quest extends Component {

  clock = null
  start = 0
  t = 0
  limit = 10

  startClock () {
    const {
      timerActions: {
        setTime,
      },
    } = this.props

    this.start = Date.now()

    clearInterval(this.clock)

    this.clock = setInterval(() => {
      this.t = Date.now() - this.start

      if (this.t >= this.limit) {
        this.stopClock()
        setTime(this.limit)

        this.handleQuestChoice('timeout')
      } else {
        setTime(this.t)
      }
    }, 10)
  }

  stopClock () {
    const {
      resultsActions: {
        updateResultsTime,
      }
    } = this.props


    updateResultsTime(this.t)

    clearInterval(this.clock)
  }

  resetClock () {
    const {
      timerActions: {
        setTime,
      },
    } = this.props

    clearInterval(this.clock)
    this.start = 0
    this.t = 0

    setTime(0)
  }

  maybeInitQuest () {
    const {
      index,
      actions: {
        setQuest,
        setNextQuestLink,
      },
      match: {
        params: {
          quest_id,
        },
      },
    } = this.props

    let questIndex = parseInt(quest_id)


    if (questIndex !== index) {
      this.resetClock()
      this.startClock()

      setQuest(questIndex)
      setNextQuestLink()
    }
  }

  restartApp () {
    const {
      index,
      actions: {
        setQuest,
        setNextQuestLink,
        resetQuests,
      },
      quizActions: {
        resetQuiz
      },
      resultsActions: {
        resetPointsAndTime,
      },
    } = this.props

    this.resetClock()

    setQuest(null)
    resetQuiz()
    resetQuests()
    resetPointsAndTime()

    this.props.history.push('/') // @todo make dynamic '/quiz'
  }

  componentDidUpdate () {
    this.maybeInitQuest()
  }

  componentDidMount () {
    const {
      initialized,
      actions: {
        resetQuests,
      },
    } = this.props

    if (!initialized)
      this.restartApp()

    resetQuests()

    this.limit = 45000 // this.props.msLimit
    console.log('limit', this.limit)

    this.maybeInitQuest()
  }

  componentWillUnmount () {
    // this.resetClock()
  }

  handleQuestChoice (key) {
    const {
      quest,
      actions: { setQuestChoice },
      resultsActions: {
        addPoint,
      }
    } = this.props

    this.stopClock()

    setQuestChoice(key)



    if (quest.answer === key)
      addPoint()
  }



  render () {
    const {
      quest,
      loaded,
      nextLink,
      strings
    } = this.props

    const keys = ['a', 'b', 'c', 'd', 'timeout']
    const answered = loaded ? keys.indexOf(quest.choice) !== -1 : false
    const solved = loaded ? quest.answer === quest.choice : false


    const buttonClass = (key) => {

      let btnClassName = 'btn btn-primary'


      if (answered && key === quest.answer)
        btnClassName = btnClassName + ' btn-success'

      if (answered && key !== quest.answer)
        btnClassName = btnClassName + ' btn-error'

      if (answered)
        btnClassName = btnClassName + ' btn-disabled'


      return btnClassName
    }


    return (
      <div className="view view--quest">

        <Navbar/>

        <button className="restart" onClick={() => { this.restartApp() }}>Restart</button>

        {loaded && (
          <div>
            <div className="quest__question">
              <div className="container-fluid">
                <div className="row">
                  <h3>{ quest.question }</h3>
                </div>
              </div>
            </div>
            <div className="quest__choices">
              <div className="container-fluid">
                <div className="row">
                  {['a', 'b', 'c', 'd'].map(key => (
                    <div className="col-12 col-sm-6" key={ "choice-" + key }>
                      <ChoiceButton onSubmit={ () => { this.handleQuestChoice(key) } } className={ buttonClass(key) } text={quest.choices[key]}/>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}

        {loaded && (
          <Modal show={ answered } className={ solved ? 'modal--success' : 'modal--error' }>
            <div className="">


              {solved ? (
                <div>
                  <p className="text--notification">{ strings.right }</p>
                  <p className="text--question">{ quest.question }</p>

                  <div className="feedback">
                    <p className="text--answer">{ quest.choices[quest.choice] }</p>
                  </div>
                </div>
              ) : (
                <div>
                  {quest.choice !== 'timeout' ? (
                    <div>
                      <p className="text--notification">{ strings.wrong }</p>
                      <p className="text--question">{ quest.question }</p>
                      {/*<p className="text--choice">{ quest.choices[quest.choice] }</p>*/}
                      <div className="feedback">
                        <p className="text--note">{ strings.would_have_been }</p>
                        <p className="text--answer">{ quest.choices[quest.answer] }</p>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <p className="text--notification">{ strings.timeout }</p>
                      <p className="text--question">{ quest.question }</p>

                      <div className="feedback">
                        <p className="text--note">{ strings.would_have_been }</p>
                        <p className="text--answer">{ quest.choices[quest.answer] }</p>
                      </div>
                    </div>
                  )}
                </div>
              )}

              <div className="modal__footer">
                <Link to={ nextLink } className="btn btn-primary button-lg">
                  { nextLink === '/results' ? strings.to_results : strings.next_question }
                </Link>
              </div>


            </div>
          </Modal>
        )}
      </div>
    )
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Quest)
