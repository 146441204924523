import React from 'react'
import { connect } from 'react-redux'



const mapStateToProps = state => ({
  active: state.timer.active,
  min: 0,
  max: state.config.questTimeLimit,
  value: state.timer.ms,
})



const ProgressBar = ({value, active, min, max}) => {

  const style = (width) => ({
    width: width + '%',
    height: '30px',
  })


  let s = value / 1000 // input comes as ms, max as s

  // let percentage = max / 100 // 12 / 100 = 0.12
  let width = ((s - min) * 100) / (max - min)

  // (value / 1000) / 12 = 1
  // value / max = 1

  // (((value / 1000) / 100) * percentage) // (12 / 100 = 1) * 0.12

  // console.log(width)

  return (
    <div className={ active ? 'progress progress--active' : 'progress' }>
      <div className="progress__bar" style={ style(width) }/>
    </div>
  )
}



export default connect(mapStateToProps)(ProgressBar)