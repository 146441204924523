import initialState from './_state'
import * as constants from './_constants'


const timer = ( state = initialState, action ) => {
  const {
    type
  } = action

  let update = { ...state }

  switch (type) {

    case constants.UPDATE_TIMER :
      update.ms = Date.now() - update.start
      break


    case constants.START_TIMER :
      update.active = true
      update.start = Date.now()
      break


    case constants.STOP_TIMER :
      update.active = false
      break


    case constants.RESET_TIMER :
      update.ms = 0
      update.active = false
      break


    case constants.SET_TIME :
      update.ms = action.payload
      break


    case constants.SET_START_TIME :
      update.start = action.payload
      break


    default :
      break
  }

  return update
}

export default timer