import { createStore } from 'redux'
import { combineReducers } from 'redux'

import quest from './quest'
import timer from './timer'
import results from './results'
// import slider from './slider'
import quiz from './quiz'

const rootState = {
  amountOfQuests: 10,
  questTimeLimit: 45,
  paths: {
    quest: '/:quest_id',
    quiz: '/',
    result: '/result',
    slides: null,
  },
}

const config = (state = rootState, action) => {
  return state
}

const rootReducer = combineReducers({
  config,
  quest,
  timer,
  results,
  // slider,
  quiz,
})

export default createStore(rootReducer)
