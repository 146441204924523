import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'


/**
 * Routes/Components
 */
// import Home from './routes/Home'
import Quiz from './routes/Quiz'
import Quest from './routes/Quest'
import Results from './routes/Results'
// import Slider from './routes/Slider'


// import Navbar from './components/Navbar'


// import AnimatedSwitch from "./Switch";


/**
 * Styles
 */
import './App.scss';

const App = () => (
  <Router>
    {/*<Navbar/>*/}

    <Switch>
      <Route
        path="/"
        exact
        component={ Quiz }
      />
      {/*<Route
        path="/quiz"
        exact
        component={ Quiz }
      />*/}

      <Route path="/results" exact component={ Results } />

      <Route
        path="/:quest_id"
        exact
        component={ Quest }
      />
      {/*<Route path="/slideshow/:slider_id" exact component={ Slider } />*/}
    </Switch>
  </Router>
)

export default App // QuizApp // App