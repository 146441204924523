import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'


import * as actions from '../../store/timer/_actions'
import * as questActions from '../../store/quest/_actions'

const mapStateToProps = state => ({
  active: state.timer.active,
  ms: state.timer.ms,
  start: state.timer.start,
  limit: state.config.questTimeLimit,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
  questActions: bindActionCreators(questActions, dispatch)
})



class Timer extends React.Component {
  render () {
    const { active, ms, limit } = this.props

    let msCounting = (limit * 1000) - ms

    let centiseconds = ('0' + (Math.floor(msCounting / 10) % 100)).slice(-2)
    let seconds = ('0' + (Math.floor(msCounting / 1000) % 60)).slice(-2)
    let minutes = ('0' + (Math.floor(msCounting / 60000) % 60)).slice(-2)
    let hours = ('0' + Math.floor(msCounting / 3600000)).slice(-2)

    return (
      <div className={ active ? 'timer timer--active' : 'timer' }>
        <span className="timer__hours">{ hours }</span>
        <span className="timer__minutes">{ minutes }</span>
        <span className="timer__seconds">{ seconds }</span>
        <span className="timer__centiseconds">{ centiseconds }</span>
      </div>
    )
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(Timer)