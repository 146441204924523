import { shuffle } from 'lodash'
import axios from 'axios'

import initialState from './_state'
import * as constants from './_constants'


// import { questions } from '../questions-fi'
// import _ from 'lodash'

const questions = []

function getRandomQuests (n) {
  let set = shuffle(questions)
  let selected = set.slice(0, n).map(quest => {
    quest.choice = ''

    return { ...quest }
  })

  return [ ...selected ]
}


const quest = (state = initialState, action) => {
  const {
    type,
  } = action

  let update = {...state}


  switch (type) {

    case constants.LOAD_QUESTS :
      console.log('action.payload', action.payload)

      action.payload.questions.map(q => questions.push(q))
      update.strings = action.payload.strings
      update.apiLoaded = true
      break;

    case constants.RESET_QUESTS :
      // @todo set amount of quests via config state
      update.all = getRandomQuests(10)
      update.current = update.all[0]
      break


    case constants.SET_CURRENT_QUEST :

      if (null === action.payload) {

        update.index = null
        update.current = update.all[0]
        update.loaded = true

      } else {

        update.index = parseInt(action.payload)
        update.current = update.all[update.index]
        update.loaded = true

      }
      break


    case constants.SET_QUEST_CHOICE :
      update.current.choice = action.payload

      axios
        .post(`${update.apiBase}/update`, {id: update.current.id, choice: action.payload, })
        .then(({ data }) => {
          console.log(data)
        })
        .catch( error => {
          console.error(error)
        })
      break


    case constants.SET_NEXT_QUEST_LINK :
      let prefix = '' // '/quest' // @todo make dynamic through config state
      update.nextLink = update.index + 1 <= update.all.length - 1
        ? prefix + '/' + (update.index + 1)
        : '/results'
      break;


    default:
      break
  }

  return update
}

export default quest