import React from 'react'



import ProgressBar from './partials/progressBar'
import Countdown from './partials/countdown'
import Points from './partials/points'

import './Navbar.scss'


const navbar = () => {
  return (
    <div className="navbar navbar--quest">
      <div className="container-fluid">
        <div className="navbar__row">
          <Countdown/>
          <Points/>
        </div>
      </div>

      <ProgressBar />
    </div>
  )
}


export default navbar