import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { bindActionCreators } from 'redux'
import * as actions from '../store/quiz/_actions'
import * as actionsQuest from '../store/quest/_actions'

import Modal from '../components/Modal'

import './Quiz.scss'

const mapStateToProps = state => ({
  username: state.quiz.username,
  initialized: state.quiz.initialized,
  users: state.quiz.ranking,
  msLimit: state.config.questTimeLimit,
  strings: state.quest.strings,
  apiLoaded: state.quest.apiLoaded,
  apiBase: state.quest.apiBase,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
  actionsQuest: bindActionCreators(actionsQuest, dispatch),
})

class Quiz extends React.Component {

  countdown = null

  constructor () {
    super(...arguments)

    this.state = {
      showModal: false,
      keys: [
        ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p',],
        ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l',],
        ['shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'delete'],
      ],
      name: '',
      uppercase: false,
      validationError: '',
    }
  }

  keyboardInput (key) {
    const { name, uppercase } = this.state

    let update = name


    switch (key) {
      case 'delete' :
        update = name.length > 0
          ? update.substring(0, name.length - 1)
          : update
        break
      case 'shift' :
        this.setState({ uppercase: !uppercase })
        break
      default :
        let transformed = uppercase ? key.toUpperCase() : key
        update = update + transformed

        this.setState({ uppercase: false })
        break
    }

    this.setState({ name: update })
  }

  maybeStartQuiz () {
    const { name } = this.state
    const { users, strings, actions: { initializeQuiz, setUserName } } = this.props

    if (name.length <= 0) {
      this.setState({ showModal: false, validationError: strings.validation_user })
    } else if (users.findIndex(u => u.username.toLowerCase() === name.toLowerCase() ) >= 0) {
      this.setState({ showModal: false, validationError: strings.validation_user_exists })
    } else {
      setUserName(name)
      initializeQuiz()

      this.setState({ showModal: true, validationError: '' })

      this.countdown = setTimeout(() => {
        this.props.history.push('/0') // @todo make dynamic /quest
      }, 3500)

    }
  }

  componentDidMount () {
    const { apiBase, actionsQuest: { loadQuests } } = this.props

    axios
      .get(`${apiBase}/get`)
      .then(({ data }) => {
        console.log('dispatched', data)

        loadQuests(data)
      })


    console.log('quiz mounted', this.props.apiLoaded)
  }

  componentDidUpdate () {
    console.log('quiz updated', this.props.apiLoaded)
  }

  componentWillUnmount () {
    clearTimeout(this.countdown)
  }

  static getDerivedStateFromProps(props, current_state) {
    console.log('update', props)

    return props
  }

  render () {
    const { showModal, name, keys, uppercase, validationError } = this.state
    const { msLimit, strings, apiLoaded } = this.props

    let inputClassName = 'username'
    let hasValidationError = validationError !== ''


    if (name.length <= 0)
      inputClassName += ' username--placeholder'

    if (hasValidationError && name.length > 0)
      this.setState({ validationError: '' })

    if (hasValidationError)
      inputClassName += ' username--error'

    console.log('rendering section ', apiLoaded)
    return (
      <div className="view view--quiz">
      {
        apiLoaded && (
          <div className="quiz__header">
            <div className="container-fluid">
              <div className="quiz__header__intro">
                <p className="text--welcome">{ strings.welcome }</p>
                <p className="text--note">{ strings.intro }</p>
                <p className="text--username">
                  <span className={ inputClassName }>{ name.length > 0 ? name : strings.placeholder_user }</span>
                  {/*<input className="form-control" placeholder="Your Nickname" onChange={ event => { setUserName(event.target.value) } }/>*/}
                </p>

                {hasValidationError && (
                  <p className="text--validation">{ validationError }</p>
                )}


                <div className="keyboard">
                {keys.map((row, index) => (
                  <div className="keyboard__row" key={ 'row--' + index }>
                    {row.map(k => (
                      <button className={ uppercase ? 'key key--upper key--' + k : 'key key--' + k } onClick={ () => { this.keyboardInput(k)} } key={ k }>
                        { k }
                      </button>
                    ))}
                  </div>
                ))}
                </div>
                <button className={ name.length <= 0 ? 'btn btn-disabled btn-lg' : 'btn btn-primary btn-lg' } onClick={ () => { this.maybeStartQuiz() } }>{ strings.start }</button>
              </div>
            </div>
          </div>
        )
      }
      {
        apiLoaded && (
          <Modal show={ showModal }>
            <p className="text--question">{ strings.get_ready } { name.length > 0 ? ', ' + name : '' }!</p>
            <p className="text--note">{ strings.starting } <br/>{ strings.hint ? strings.hint.replace('$1', msLimit) : '' } <br/>{ strings.good_luck }</p>
          </Modal>
        )
      }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Quiz)