const state = {
  current: {},
  nextLink: {},
  all: [],
  index: null,
  loaded: false,
  showModal: false,
  strings: {},
  apiLoaded: false,
  apiBase: 'https://bloom-bioeconomy.eu/wp-json/quiz/v1',
}

export default state